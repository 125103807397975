/* eslint-disable @typescript-eslint/no-explicit-any */
/*import useSentry from "@/plugins/sentry";*/
import ObjectUtils from "@/common/utils/object-utils";
import { AxiosResponse } from "axios";
import router from "@/router";
import ErrorType from "@/common/constants/error";
import { showError } from "@/common/utils/notification-utils";

export const responseInterceptor = (res: AxiosResponse<any>): AxiosResponse<any> => {
  return res;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onRejected = (error: any): Promise<never> => {
 /* const { captureApiError } = useSentry();*/
  /**
   * If the response contains data, trim it so that we protect sensitive information.
   * @param res The axios response
   */
  const trimData = (res: AxiosResponse) => {
    const clone = ObjectUtils.DeepClone(res);
    if (clone.data.data) delete clone.data.data;
    return clone;
  };

  let errorType = null;

  if (error.response === undefined) {
    errorType = ErrorType.SERVICE_UNAVAILABLE;
  } else {
    switch (error.response.status) {
      case 400:
        {
          showError(error.response.data.message);
        }
        break;
      case 401:
        errorType = ErrorType.UNAUTHORIZED;
        break;
      case 403:
        errorType = ErrorType.FORBIDDEN;
        break;
      case 404:
        errorType = ErrorType.NOT_FOUND;
        break;
      case 500:
        errorType = ErrorType.SERVER_ERROR;
        break;
    }
  }

  if (errorType && (errorType === ErrorType.UNAUTHORIZED || errorType === ErrorType.FORBIDDEN)) {
    router.push({ name: "login" });
  }
  if (errorType && (errorType === ErrorType.NOT_FOUND || errorType === ErrorType.SERVER_ERROR)) {
    router.push({ name: "error", params: { errorType: errorType } });
  }

  return Promise.reject(error);
};
