<template>
  <Listbox v-slot="{ open }">
    <div class="relative">
      <ListboxButton
:class="[
          isDarkTheme ? 'bg-background-primary border border-white focus:outline-none' : 'bg-background-input',
          open && !isDarkTheme ? 'ring-1 ring-primary-light-100' : '',
          'relative flex items-center w-full h-8 pl-3 pr-8 text-left rounded-lg cursor-default sm:text-sm',
        ]">
        <span
v-if="placeholder !== '' && !modelValue?.name"
              :class="[isDarkTheme ? 'text-white' : 'text-secondary-light-text/30', 'text-xs font-regular']">
          {{ placeholder }}
        </span>
        <span
v-else-if="showTitleOnSelect || (!showTitleOnSelect && !modelValue?.name)"
              :class="[isDarkTheme ? 'text-white' : 'text-secondary-light-text/30', isTitleBold ? 'font-semibold' : 'font-regular', 'text-xs']">
          {{ title }}
        </span>
        <app-spinner v-if="isLoading" class="ml-2" height="20" width="20"></app-spinner>
        <span
v-else
              :class="[
            !showTitleOnSelect ? '' : 'ml-2.5',
            isDarkTheme ? 'text-secondary-dark-text' : 'text-primary-light-text',
            'text-xs block truncate',
          ]">
          {{ modelValue && modelValue?.name !== "" ? modelValue?.name : placeholder }}
        </span>
        <button
v-if="modelValue?.name && hasClear"
                type="button"
                class="absolute right-8 flex items-center justify-center w-4 h-4 -mr-2 text-gray-500 hover:text-gray-700"
                title="Clear selection"
                @click.stop="clearSelection">
          <XMarkIcon  :class="[isDarkTheme ? 'text-white' : 'text-primary-light-text', 'w-4 h-4']" aria-hidden="true" />
        </button>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronDownIcon v-if="!open" :class="[isDarkTheme ? 'text-white' : 'text-primary-light-text', 'w-4 h-4']" aria-hidden="true" />
          <ChevronUpIcon v-if="open" :class="[isDarkTheme ? 'text-white' : 'text-primary-light-text', 'w-4 h-4']" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions
:class="[
            isDarkTheme ? 'bg-white ring-primary-light-100' : 'bg-background-input ring-border-light',
            'absolute w-full py-1 mt-2.5 overflow-auto text-xs rounded-md shadow-lg max-h-40 ring-1 z-10 focus:outline-none z-20',
          ]">
          <ListboxOption v-for="option in options" v-slot="{ active }" :key="option.value" :value="option" as="template">
            <li
:class="[
                active || isSelected(option) ? 'text-primary-light-100 bg-secondary-light-100' : 'text-primary-light-text',
                'cursor-default select-none relative py-2 px-4',
              ]"
                @click="onInput(option)">
              <span
v-tippy="{ content: option.name, arrow: false }"
                    :class="[isSelected(option) ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ option.name }}
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
  import { defineProps, defineEmits, PropType } from 'vue';
  import AppSpinner from "@/components/ui/AppSpinner.vue";
  import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
  import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/vue/20/solid";
  import { directive as tippyDirective } from "vue-tippy";
  import { LanguageCurrencyItem } from "@/stores/profile/types";
  import { DefaultObject } from "@/stores/player/types";


  const props = defineProps({
    isDarkTheme: {
      type: Boolean,
      default: true
    },
    hasClear: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isTitleBold: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object as PropType<DefaultObject>,
      default: () => ({} as DefaultObject)
    },
    options: {
      type: Array as PropType<LanguageCurrencyItem[]>,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    showTitleOnSelect: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
  });


  const emit = defineEmits(['update:modelValue']);


  function isSelected(option: LanguageCurrencyItem) {
    return props.modelValue && 'value' in props.modelValue && props.modelValue.value.toString() === option.value.toString();
  }

  function onInput(option: LanguageCurrencyItem) {
    emit('update:modelValue', option);
  }
  function clearSelection() {
    emit('update:modelValue', {} as DefaultObject); 
  }
  const tippy = tippyDirective;

</script>
