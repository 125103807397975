import { createApp } from "vue";

import { createPinia } from "pinia";

import App from "./App.vue";
import configs from "./configs";
import i18n from "./plugins/vue-i18n";
import router from "./router";
import Toast, { POSITION } from "vue-toastification";
import VueApexCharts from "vue3-apexcharts";
import VueTippy from "vue-tippy";
import "@/assets/index.css";
import "tippy.js/dist/tippy.css";
import "vue-toastification/dist/index.css";
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';
const app = createApp(App);


app.use(createPinia());
app.use(i18n);
app.use(router);
app.use(Toast, { position: POSITION.TOP_CENTER, pauseOnFocusLoss: false });
app.use(VueApexCharts);
app.use(PerfectScrollbarPlugin);
app.use(VueTippy, {
  directive: "tippy",
  defaultProps: { placement: "right", theme: "gool" },
});

app.mount("#app");

export default app;
